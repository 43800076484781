import { Component, Vue } from 'vue-property-decorator'

//components
import Navbar from '@/components/navbar/Navbar.vue'
import ListPanel from '@/components/listPanel/ListPanel.vue'
import ListMenuOption from '@/components/listMenuOption/ListMenuOption.vue'
import ListGroups from '@/components/listGroups/ListGroups.vue'
import PrivateContent from '@/components/privateContent/PrivateContent.vue'
import HistoryProcess from '@/components/historyProcess/HistoryProcess.vue'

//classes
import { Alert } from '@/services/notification'

//models
import { Form, Input } from '@/components/wrappers/molds/Form';
import { User } from '@/molds/User'

//Vuex
const namespace = 'user';
import { Action, Getter } from 'vuex-class';

//wrappers
import { 
	Container, 
	Box, 
	WTabs,
	WColumns,
	WColumn,
  WButton,
  WHr,
	WForm,
	WSubtitle,
	Loader
} from '@/components/wrappers';

@Component({
	components:{
		Navbar,
		Container,
		Box,
		ListPanel,
		WTabs,
		WColumns,
    WColumn,
    ListMenuOption,
    WButton,
    WHr,
    ListGroups,
    PrivateContent,
    HistoryProcess,
		WForm,
		WSubtitle,
		Loader
	}
})
export default class NewUser extends Vue { 

	@Action('createUser', { namespace }) createUser: any;
	@Getter('newUser', { namespace }) newUser!: User;
	@Getter('loader', { namespace }) isLoading!: boolean;
	@Getter('error', { namespace }) error!: any;

	alert = new Alert(this.$store)
	user: User | null = null
	
	async dispatchCreateUser(user: User): Promise<void> {			
		await this.createUser(user)
		if(this.error){
			this.alert.apiMessage("Não foi possível criar usuário.", this.error, "danger")
		}else{
			this.alert.success('Usuário criado com sucesso!')
			this.user = this.newUser
		}
	}

	goToUser(user: User): void {
		this.$router.push(`user/${user.idUser}`)
	}

	get grid(): Input[][] {
		return [
			[
				{
					fieldName: "name",
					label: "Nome",
					type: "text",
					maxlength: 50,
					placeholder:"nome",
					required: true,
				},
				{
					fieldName: "login",
					label: "E-mail/login",
					type: "email",
					maxlength: 50,
					placeholder:"email",
					required: true,
				},
			],
			[	
				{
					fieldName: "document",
					label: "Documento",
					type: "document",
					maxlength: 12,
					placeholder:"documento",
					required: true,
				},
				{
					fieldName: "birthDate",
					label: "Nascimento",
					type: "date",
					maxlength: 10,
					placeholder:"data de nascimento",
					required: true,
				},
			],
		]
	}

	get form(): Form {
		return {
			flex: false,
			indent: true,
			convertDate: false,
			inputsPadding: '10px',
			grid: this.grid
		}
	}

}
